import { Component, OnDestroy, Inject } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { WebsiteService } from './common-theme/index';
import { fadeAnimation } from './common-theme/animations/fade.animation';
import { FbqService, GtagService } from './common-theme';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fadeAnimation]
})
export class AppComponent implements OnDestroy {
  subscription: Subscription;
  public currentRoute: string = '/';

  constructor(
    public websiteService: WebsiteService,
    private translate: TranslateService,
    public fbqService: FbqService,
    public gtagService: GtagService,
    router: Router,
    @Inject('WINDOW') private window: any
  ) {

    if (router) {
      router.events.subscribe(s => {
        if (s instanceof NavigationEnd) {
          const tree = router.parseUrl(router.url);
          this.currentRoute = router.url;

          if (!tree.fragment && this.window)
            this.window.scrollTo(0, 0);
        }
      });
    }

    this.translate.setDefaultLang('ro');
    this.translate.use('ro');
  }

  scrollToTop(): void {
    const scrollToTop = this.window.setInterval(
      () => {
        const pos = this.window.pageYOffset;
        if (pos > 0)
          this.window.scrollTo(0, pos - 150);
        else
          this.window.clearInterval(scrollToTop);
      },
      16
    );
  }

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }
}
