import { Component, Input, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Block } from './../../common-theme/models/index';
import { LocaleAwareBaseComponent } from './../../common-theme/index';

@Component({
  selector: 'app-showcase',
  templateUrl: './showcase.component.html',
  styleUrls: ['./showcase.component.scss']
})
export class ShowcaseComponent extends LocaleAwareBaseComponent implements AfterViewInit {
  @ViewChild('video', { static: false }) video: ElementRef;
  @Input() block: Block;
  @Input() videoUri: string = null;
  @Input() blockImage1: Block;
  @Input() blockImage2: Block;
  @Input() blockImage3: Block;

  constructor() {
    super();
  }

  ngAfterViewInit(): void {
    this.video.nativeElement.play();
  }
}
