import { Component, Injector, OnInit, OnDestroy } from '@angular/core';
import { SeoAwareBaseComponent } from '../../common-theme/seo/seo-aware-base.component';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Block } from './../../common-theme/models/index';
import { BlockHelperService, EmbedVideoService } from './../../common-theme/index';

@Component({
  selector: 'app-project3',
  templateUrl: './project3.component.html',
  styleUrls: ['./project3.component.scss']
})
export class Project3Component extends SeoAwareBaseComponent implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription();
  public videoInnerHTML: any;
  public blocks: Block[];
  sliders: any[];

  constructor(
    public blockHelper: BlockHelperService,
    private embedService: EmbedVideoService,
    public route: ActivatedRoute,
    public injector: Injector
  ) {
    super (injector);
  }

  ngOnInit(): void {
    this.subscription.add(this.route.data.subscribe(data => {
      this.blocks = data.blocks;

      this.setTitle(this.blockHelper.filterBlocksByKey(this.blocks, 'seo').properties.title)
      this.setMetaDescription(this.blockHelper.filterBlocksByKey(this.blocks, 'seo').properties.description)

      this.videoInnerHTML = this.embedService.embed(
        this.blockHelper.filterBlocksByKey(this.blocks, 'project-header').properties.youtube_url
      );

      this.sliders = this.blocks.filter(obj => {
        return obj.key.startsWith('slider');
      });
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
