<app-showcase
  [block]="blockHelper.filterBlocksByKey(blocks, 'showcase')"
  videoUri="https://s3.eu-central-1.amazonaws.com/video.vauntsite.ro/1development.ro/home.mp4"
  [blockImage1]="blockHelper.filterBlocksByKey(blocks, 'showcase-image1')"
  [blockImage2]="blockHelper.filterBlocksByKey(blocks, 'showcase-image2')"
  [blockImage3]="blockHelper.filterBlocksByKey(blocks, 'showcase-image3')"
></app-showcase>

<section class="history-section">
  <div class="container">
    <p class="quote text-right">{{ blockHelper.filterBlocksByKey(blocks, 'quote1').properties.text }}</p>

    <div
      class="history-body"
      fxLayout="row wrap"
      fxLayout.gt-xs="row"
      fxLayoutGap.gt-xs="100px"
      fxLayoutAlign="center center"
    >
      <div
        fxFlex="100%"
        fxFlex.gt-xs="60%"
      >
        <p>{{ blockHelper.filterBlocksByKey(blocks, 'building-history').properties.description }}</p>
      </div>
    </div>
  </div>
</section>

<section
  class="projects-concept-section"
  [ngStyle]="{'background-image': 'url(' + blockHelper.filterBlocksByKey(blocks, 'projects-concept-quote').image.url + ')'}"
>
  <div class="container">
    <p class="quote">{{ blockHelper.filterBlocksByKey(blocks, 'projects-concept-quote').properties.text }}</p>
  </div>
</section>

<section class="believe-section">
  <div class="container">
    <h2 class="text-center">{{ blockHelper.filterBlocksByKey(blocks, 'believe-in-section').properties.section_title }}</h2>
    <div
      class="believe-grid"
      fxLayout="row wrap"
    >
      <div
        fxFlex.gt-sm="33.3333%"
        class="believe-item text-center"
      >
        <img
          src="/assets/svg/excellence.svg"
          alt=""
        >
        <h3>{{ blockHelper.filterBlocksByKey(blocks, 'believe-in1').properties.title }}</h3>
        <p>{{ blockHelper.filterBlocksByKey(blocks, 'believe-in1').properties.description }}</p>
      </div>
      <div
        fxFlex.gt-sm="33.3333%"
        class="believe-item text-center"
      >
        <img
          src="/assets/svg/comfort.svg"
          alt=""
        >
        <h3>{{ blockHelper.filterBlocksByKey(blocks, 'believe-in2').properties.title }}</h3>
        <p>{{ blockHelper.filterBlocksByKey(blocks, 'believe-in2').properties.description }}</p>
      </div>
      <div
        fxFlex.gt-sm="33.3333%"
        class="believe-item text-center"
      >
        <img
          src="/assets/svg/responsability.svg"
          alt=""
        >
        <h3>{{ blockHelper.filterBlocksByKey(blocks, 'believe-in3').properties.title }}</h3>
        <p>{{ blockHelper.filterBlocksByKey(blocks, 'believe-in3').properties.description }}</p>
      </div>
    </div>
    <p class="quote no-icon bold">{{ blockHelper.filterBlocksByKey(blocks, 'believe-in-section').properties.text }}</p>
    <div
      fxLayout="column"
      fxLayout.gt-xs="row"
      fxLayoutAlign="center center"
    >
      <a
        [routerLink]="basePath + blockHelper.filterBlocksByKey(blocks, 'believe-in-section').properties.button1_link"
        class="btn-outline--white"
      >
        {{ blockHelper.filterBlocksByKey(blocks, 'believe-in-section').properties.button1 }}
      </a>
      <a
        [routerLink]="basePath + blockHelper.filterBlocksByKey(blocks, 'believe-in-section').properties.button2_link"
        class="btn-outline--white"
      >
        {{ blockHelper.filterBlocksByKey(blocks, 'believe-in-section').properties.button2 }}
      </a>
    </div>
  </div>
</section>
