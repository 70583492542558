import * as tslib_1 from "tslib";
import { Injector, OnInit, OnDestroy } from '@angular/core';
import { SeoAwareBaseComponent } from '../../common-theme/seo/seo-aware-base.component';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { BlockHelperService } from './../../common-theme/index';
var Project2Component = /** @class */ (function (_super) {
    tslib_1.__extends(Project2Component, _super);
    function Project2Component(blockHelper, route, injector) {
        var _this = _super.call(this, injector) || this;
        _this.blockHelper = blockHelper;
        _this.route = route;
        _this.injector = injector;
        _this.subscription = new Subscription();
        return _this;
    }
    Project2Component.prototype.ngOnInit = function () {
        var _this = this;
        this.subscription.add(this.route.data.subscribe(function (data) {
            _this.blocks = data.blocks;
            _this.setTitle(_this.blockHelper.filterBlocksByKey(_this.blocks, 'seo').properties.title);
            _this.setMetaDescription(_this.blockHelper.filterBlocksByKey(_this.blocks, 'seo').properties.description);
            _this.sliders = _this.blocks.filter(function (obj) {
                return obj.key.startsWith('slider');
            });
        }));
    };
    Project2Component.prototype.ngOnDestroy = function () {
        this.subscription.unsubscribe();
    };
    return Project2Component;
}(SeoAwareBaseComponent));
export { Project2Component };
