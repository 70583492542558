<app-showcase
  [block]="blockHelper.filterBlocksByKey(blocks, 'showcase')"
  videoUri="https://s3.eu-central-1.amazonaws.com/video.vauntsite.ro/1development.ro/home.mp4"
  [blockImage1]="blockHelper.filterBlocksByKey(blocks, 'showcase-image1')"
  [blockImage2]="blockHelper.filterBlocksByKey(blocks, 'showcase-image2')"
  [blockImage3]="blockHelper.filterBlocksByKey(blocks, 'showcase-image3')"
></app-showcase>

<section class="award-section">
  <div class="container">
    <div
      fxLayout="row wrap"
      fxLayout.gt-xs="row"
      fxLayoutGap.gt-xs="100px"
      fxLayoutAlign="center center"
    >
      <div
        fxFlex="100%"
        fxFlex.gt-xs="60%"
      >
        <p>{{ blockHelper.filterBlocksByKey(blocks, 'about-us').properties.text }}</p>
      </div>
    </div>
  </div>
</section>

<section class="latest-project-secion">
  <div class="container">
    <div class="section-title">
      <h2>{{ blockHelper.filterBlocksByKey(blocks, 'latest-project').properties.section_title }}</h2>
      <label>{{ blockHelper.filterBlocksByKey(blocks, 'latest-project').properties.section_label }}</label>
    </div>

    <div
      class="latest-project-grid"
      fxLayout="column"
      fxLayoutAlign="center center"
      fxLayout.gt-sm="row"
      fxLayoutAlign.gt-sm="space-between"
    >
      <div>
        <figure
          class="special"
        >
            <div>
              <a [routerLink]="basePath + blockHelper.filterBlocksByKey(blocks, 'latest-project1').properties.link">
                <div
                  class="img-wrapper"
                  [ngStyle]="{'background-image': 'url(' + blockHelper.filterBlocksByKey(blocks, 'latest-project1').image.width_560_url + ')'}"
                ></div>
                <div
                  class="img-wrapper switch-image-hover"
                  [ngStyle]="{'background-image': 'url(' + blockHelper.filterBlocksByKey(blocks, 'latest-project1-hover').image.width_560_url + ')'}"
                ></div>
              </a>
            </div>
            <figcaption>
              <a [routerLink]="basePath + blockHelper.filterBlocksByKey(blocks, 'latest-project1').properties.link">
                {{ blockHelper.filterBlocksByKey(blocks, 'latest-project1').properties.title }}
              </a>
            </figcaption>
        </figure>
      </div>
      <div>
        <figure>
          <div>
            <a [routerLink]="basePath + blockHelper.filterBlocksByKey(blocks, 'latest-project2').properties.link">
              <div
                class="img-wrapper"
                [ngStyle]="{'background-image': 'url(' + blockHelper.filterBlocksByKey(blocks, 'latest-project2').image.width_560_url + ')'}"
              ></div>
              <div
                class="img-wrapper switch-image-hover"
                [ngStyle]="{'background-image': 'url(' + blockHelper.filterBlocksByKey(blocks, 'latest-project2-hover').image.width_560_url + ')'}"
              ></div>
            </a>
          </div>
          <figcaption>
            <a [routerLink]="basePath + blockHelper.filterBlocksByKey(blocks, 'latest-project2').properties.link">
              {{ blockHelper.filterBlocksByKey(blocks, 'latest-project2').properties.title }}
            </a>
          </figcaption>
        </figure>
        <figure>
          <div>
            <a [routerLink]="basePath + blockHelper.filterBlocksByKey(blocks, 'latest-project3').properties.link">
              <div
                class="img-wrapper"
                [ngStyle]="{'background-image': 'url(' + blockHelper.filterBlocksByKey(blocks, 'latest-project3').image.width_560_url + ')'}"
              ></div>
              <div
                class="img-wrapper switch-image-hover"
                [ngStyle]="{'background-image': 'url(' + blockHelper.filterBlocksByKey(blocks, 'latest-project3-hover').image.width_560_url + ')'}"
              ></div>
            </a>
          </div>
          <figcaption>
            <a [routerLink]="basePath + blockHelper.filterBlocksByKey(blocks, 'latest-project3').properties.link">
              {{ blockHelper.filterBlocksByKey(blocks, 'latest-project3').properties.title }}
            </a>
          </figcaption>
        </figure>
      </div>
    </div>
  </div>
</section>

<section class="award-section">
  <div class="container">
    <div class="section-title award-header">
      <h2>{{ blockHelper.filterBlocksByKey(blocks, 'award').properties.section_title }}</h2>
      <label>{{ blockHelper.filterBlocksByKey(blocks, 'award').properties.section_label }}</label>
    </div>

    <div
      class="award-footer"
      fxLayout="column"
      fxLayoutAlign="center center"
      fxLayout.gt-xs="row"
    >
      <p class="quote">{{ blockHelper.filterBlocksByKey(blocks, 'quote').properties.text }}</p>
      <div
        class="img-wrapper"
        [ngStyle]="{'background-image': 'url(' + blockHelper.filterBlocksByKey(blocks, 'quote-image1').image.width_320_url + ')'}"
      >
      </div>
    </div>

    <div
      class="award-middle"
      fxLayout.gt-xs="row"
    >
      <div class="award-description-wrapper">
        <div
          class="award-title"
          fxLayout="row"
          fxLayoutAlign="start center"
        >
          <h3>{{ blockHelper.filterBlocksByKey(blocks, 'award-mid').properties.title }}</h3>
        </div>
        <p>{{ blockHelper.filterBlocksByKey(blocks, 'award-mid').properties.description }}</p>
      </div>
    </div>

    <div
      class="award-body"
      fxLayout.gt-xs="row"
    >
      <div
        class="img-wrapper"
        [ngStyle]="{'background-image': 'url(' + blockHelper.filterBlocksByKey(blocks, 'award-image1').image.width_320_url + ')'}"
      >
      </div>
      <div class="award-description-wrapper">
        <div
          class="award-title"
          fxLayout="row"
          fxLayoutAlign="start center"
        >
          <h3>{{ blockHelper.filterBlocksByKey(blocks, 'award').properties.title }}</h3>
          <img
            [src]="blockHelper.filterBlocksByKey(blocks, 'award-image2').image.url"
            alt=""
          >
        </div>
        <p>{{ blockHelper.filterBlocksByKey(blocks, 'award').properties.description }}</p>
        <div class="text-right">
          <a
            [routerLink]="basePath + blockHelper.filterBlocksByKey(blocks, 'award').properties.link"
            class="btn-outline--white"
          >{{ blockHelper.filterBlocksByKey(blocks, 'award').properties.button }}</a>
        </div>
      </div>
    </div>
  </div>
</section>
