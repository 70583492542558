import * as tslib_1 from "tslib";
import { AfterViewInit, ElementRef } from '@angular/core';
import { Block } from './../../common-theme/models/index';
import { LocaleAwareBaseComponent } from './../../common-theme/index';
var ShowcaseComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ShowcaseComponent, _super);
    function ShowcaseComponent() {
        var _this = _super.call(this) || this;
        _this.videoUri = null;
        return _this;
    }
    ShowcaseComponent.prototype.ngAfterViewInit = function () {
        this.video.nativeElement.play();
    };
    return ShowcaseComponent;
}(LocaleAwareBaseComponent));
export { ShowcaseComponent };
