<app-cookie></app-cookie>

<app-header></app-header>

<div class="site-content" [@fadeAnimation]="o.isActivated ? o.activatedRoute : ''">
  <router-outlet #o="outlet"></router-outlet>
</div>

<app-footer></app-footer>

<button
  type="button"
  class="scroll-to-top"
  id="scroll_to_top"
  (click)="scrollToTop()"
>
  <svg style="width: 100%; height: 100%;" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" width="512px" height="512px" viewBox="0 0 372.136 372.136" style="enable-background:new 0 0 372.136 372.136;" xml:space="preserve" class=""><g><g>
  	<path d="M371.682,143.271c-14.688-44.676-26.316-90.576-50.797-131.58c-2.447-4.284-10.403-5.508-12.239,0   c-17.748,42.228-36.108,83.844-47.736,127.908c-1.836,7.344,7.344,12.852,12.852,7.344c10.404-10.404,21.421-20.196,33.049-28.764   c-1.225,90.576,1.836,195.84-105.876,223.992c-47.736,12.24-100.98,5.509-140.76-25.092C18.557,284.644,9.377,231.4,12.437,181.828   c0-4.896-7.344-6.12-8.568-1.224c-23.868,110.772,66.096,197.064,176.256,181.764c54.468-7.344,100.368-33.048,123.624-85.068   c20.809-46.512,19.584-102.204,18.36-153c11.628,10.404,24.479,19.584,37.943,26.928   C366.173,154.9,374.129,150.004,371.682,143.271z M316.602,102.88c-3.672-1.224-6.12,0.612-7.345,3.672c0,0,0,0-0.611,0.612   c-9.792,3.06-18.36,7.956-26.316,13.464c9.18-29.988,21.42-59.364,33.048-88.128c15.912,29.988,25.092,62.424,35.496,94.248   C339.857,117.568,329.453,108.388,316.602,102.88z" data-original="#000000" class="active-path" data-old_color="#ffffff" fill="#ffffff"/>
  </g></g> </svg>
</button>
