import { BrowserModule, TransferState } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CommonThemeModule } from './common-theme';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { FlexLayoutModule } from '@angular/flex-layout';
import { HomeComponent } from './home/home.component';
import { InquiryComponent } from './inquiry/inquiry.component';
import { ProjectsComponent } from './projects/projects.component';
import { Project1Component } from './projects/project1/project1.component';
import { Project2Component } from './projects/project2/project2.component';
import { Project3Component } from './projects/project3/project3.component';
import { ConceptComponent } from './concept/concept.component';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { ShowcaseComponent } from './shared/showcase/showcase.component';
import { ImageCarouselComponent } from './shared/image-carousel/image-carousel.component';

import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';

// Translate
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { exportTranslateStaticLoader } from './common-theme/i18n/translate-browser-loader.service';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    InquiryComponent,
    ProjectsComponent,
    Project1Component,
    Project2Component,
    Project3Component,
    ConceptComponent,
    HeaderComponent,
    FooterComponent,
    ShowcaseComponent,
    ImageCarouselComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    FlexLayoutModule,
    HttpClientModule,
    TransferHttpCacheModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: exportTranslateStaticLoader,
        deps: [HttpClient, TransferState]
      }
    }),
    CommonThemeModule,
    MatTabsModule,
    MatIconModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
