import { Component, OnInit, Input, OnDestroy, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-image-carousel',
  templateUrl: './image-carousel.component.html',
  styleUrls: ['./image-carousel.component.scss']
})
export class ImageCarouselComponent implements OnInit, OnDestroy {

  @Input() intervalToSlide: number = 5000;
  @Input() sliders: any;
  selectedIndex: number = 0;
  interval: any;

  constructor(
    @Inject(PLATFORM_ID) private platformId: object
  ) { }

  ngOnInit(): void {
    this.initSlider();
  }

  initSlider(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.interval = setInterval(
        () => {
          if (this.selectedIndex < this.sliders.length - 1) {
            this.selectedIndex++;
          } else {
            this.selectedIndex = 0;
          }
        },
        this.intervalToSlide
      );
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  prev(): void {
    if (this.selectedIndex > 0) {
      this.selectedIndex--;
    } else {
      this.selectedIndex = this.sliders.length - 1;
    }
  }

  next(): void {
    if (this.selectedIndex < this.sliders.length - 1) {
      this.selectedIndex++;
    } else {
      this.selectedIndex = 0;
    }
  }
}
