<section class="projects-page">
  <div class="container">
    <div class="section-title">
      <h1>{{ blockHelper.filterBlocksByKey(blocks, 'projects-header').properties.section_title }}</h1>
      <label>{{ blockHelper.filterBlocksByKey(blocks, 'projects-header').properties.section_description }}</label>
    </div>

    <p class="quote">{{ blockHelper.filterBlocksByKey(blocks, 'projects-header').properties.text }}</p>

    <div class="projects-list">
      <div class="main-project text-center">
        <h2>
          <a
            [routerLink]="basePath + blockHelper.filterBlocksByKey(blocks, 'project3').properties.button_link"
          >
            <img
              class="project-logo"
              [src]="blockHelper.filterBlocksByKey(blocks, 'project3_logo').icon.url"
              [alt]="blockHelper.filterBlocksByKey(blocks, 'project3').properties.title"
            >
          </a>
        </h2>
        <div
          class="image-switcher"
        >
          <img
            [src]="blockHelper.filterBlocksByKey(blocks, 'project3').image.url"
            alt=""
          >
          <img
            [src]="blockHelper.filterBlocksByKey(blocks, 'project3-hover').image.url"
            alt=""
          >
        </div>
        <p>{{ blockHelper.filterBlocksByKey(blocks, 'project3').properties.description }}</p>
        <a
          [routerLink]="basePath + blockHelper.filterBlocksByKey(blocks, 'project3').properties.button_link"
          class="btn-outline--white"
        >{{ blockHelper.filterBlocksByKey(blocks, 'project3').properties.button }}</a>
      </div>

      <div
        class="history-body"
        fxLayout="row wrap"
        fxLayout.gt-xs="row"
        fxLayoutGap.gt-xs="100px"
      >
        <div
          fxFlex="100%"
          fxFlex.gt-xs="60%"
        >
          <h2>
            <a
              [routerLink]="basePath + blockHelper.filterBlocksByKey(blocks, 'project2').properties.button_link"
            >
              <img
                class="project-logo"
                [src]="blockHelper.filterBlocksByKey(blocks, 'project2_logo').icon.url"
                [alt]="blockHelper.filterBlocksByKey(blocks, 'project2').properties.title"
              >
            </a>
          </h2>
          <p>{{ blockHelper.filterBlocksByKey(blocks, 'project2').properties.description }}</p>
          <label>{{ blockHelper.filterBlocksByKey(blocks, 'project2').properties.label }}</label>
          <a
            [routerLink]="basePath + blockHelper.filterBlocksByKey(blocks, 'project2').properties.button_link"
            class="btn-outline--white"
          >{{ blockHelper.filterBlocksByKey(blocks, 'project2').properties.button }}</a>
        </div>
        <div
          fxFlex="100%"
          fxFlex.gt-xs="40%"
          class="text-right"
        >
          <div class="background-image-switcher">
            <div
              class="img-wrapper"
              [ngStyle]="{'background-image': 'url(' + blockHelper.filterBlocksByKey(blocks, 'project2').image.width_560_url + ')'}">
            </div>
            <div
              class="img-wrapper switch-image-hover"
              [ngStyle]="{'background-image': 'url(' + blockHelper.filterBlocksByKey(blocks, 'project2-hover').image.width_560_url + ')'}">
            </div>
            <div class="extra-details">{{ blockHelper.filterBlocksByKey(blocks, 'project2').properties.extra_details }}</div>
          </div>
        </div>
      </div>

      <div
        class="history-body"
        fxLayout="row wrap"
        fxLayout.gt-xs="row"
        fxLayoutGap.gt-xs="100px"
      >
        <div
          fxFlex="100%"
          fxFlex.gt-xs="60%"
        >
          <h2>
            <a
              [routerLink]="basePath + blockHelper.filterBlocksByKey(blocks, 'project1').properties.button_link"
            >
              <img
                class="project-logo"
                [src]="blockHelper.filterBlocksByKey(blocks, 'project1_logo').icon.url"
                [alt]="blockHelper.filterBlocksByKey(blocks, 'project1').properties.title"
              >
            </a>
          </h2>
          <p>{{ blockHelper.filterBlocksByKey(blocks, 'project1').properties.description }}</p>
          <a
            [routerLink]="basePath + blockHelper.filterBlocksByKey(blocks, 'project1').properties.button_link"
            class="btn-outline--white"
          >{{ blockHelper.filterBlocksByKey(blocks, 'project1').properties.button }}</a>
        </div>
        <div
          fxFlex="100%"
          fxFlex.gt-xs="40%"
          class="text-right"
        >
          <div class="background-image-switcher">
            <div
              class="img-wrapper"
              [ngStyle]="{'background-image': 'url(' + blockHelper.filterBlocksByKey(blocks, 'project1').image.width_560_url + ')'}">
            </div>
            <div
              class="img-wrapper switch-image-hover"
              [ngStyle]="{'background-image': 'url(' + blockHelper.filterBlocksByKey(blocks, 'project1-hover').image.width_560_url + ')'}">
            </div>
            <div class="extra-details">{{ blockHelper.filterBlocksByKey(blocks, 'project2').properties.extra_details }}</div>
          </div>
        </div>
      </div>
    </div>

  </div>
</section>
