import { Injectable, Inject, Injector, PLATFORM_ID } from '@angular/core';
import { isPlatformServer, isPlatformBrowser } from '@angular/common';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { environment } from './../../../environments/environment';

// https://github.com/DmitriyIvchenko/angular/blob/10a8e5056b9c66aa7006b02e45c13fd2bae24350/project/
// application/src/app/modules/core/services/http-request-data/http-request-data.service.ts

@Injectable()
export class LocaleService {
  static pathPrefix: string = '/lang-';

  constructor(
    @Inject('WINDOW') private window: any,
    @Inject(PLATFORM_ID) private platformId: object,
    private injector: Injector
  ) { }

  getCurrentUrl(): string {
    if (isPlatformServer(this.platformId)) {
      const request = this.injector.get(REQUEST);
      return request.url;
    } else if (isPlatformBrowser(this.platformId)) {
      return this.window.location.pathname
    } else {
      throw new Error('Platform unknown');
    }
  }

  getCurrentLocale(): string {
    const url = this.getCurrentUrl();

    if (url.startsWith(LocaleService.pathPrefix + 'ro'))
      return 'ro';
    else if (url.startsWith(LocaleService.pathPrefix + 'en'))
      return 'en';
    else
      return 'ro';
  }

  getLocaleUrl(newLang: string): string {
    let url = this.getCurrentUrl();

    if (url.endsWith('/'))
      url = url.substring(0, url.length - 1);

    if (url.startsWith(LocaleService.pathPrefix + this.getCurrentLocale()))
      return url.replace(LocaleService.pathPrefix + this.getCurrentLocale(), LocaleService.pathPrefix + newLang);
    if (environment.router_link_has_locale === true)
      return LocaleService.pathPrefix + newLang;

    throw new Error('router_link_has_locale is false');
  }
}
