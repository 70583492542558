/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./common-theme/cookie/cookie.component.ngfactory";
import * as i3 from "./common-theme/cookie/cookie.component";
import * as i4 from "@ngx-translate/core";
import * as i5 from "./shared/header/header.component.ngfactory";
import * as i6 from "./shared/header/header.component";
import * as i7 from "@angular/router";
import * as i8 from "./common-theme/seo/locale.service";
import * as i9 from "./common-theme/api/website.service";
import * as i10 from "@angular/common";
import * as i11 from "./shared/footer/footer.component.ngfactory";
import * as i12 from "./shared/footer/footer.component";
import * as i13 from "./app.component";
import * as i14 from "./common-theme/analytics/fbq.service";
import * as i15 from "./common-theme/analytics/gtag.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: { "animation": [{ type: 7, name: "fadeAnimation", definitions: [{ type: 1, expr: "* => *", animation: [{ type: 11, selector: ":enter", animation: [{ type: 6, styles: { opacity: 0 }, offset: null }], options: { optional: true } }, { type: 11, selector: ":leave", animation: [{ type: 6, styles: { opacity: 1 }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 0 }, offset: null }, timings: "0.3s" }], options: { optional: true } }, { type: 11, selector: ":enter", animation: [{ type: 6, styles: { opacity: 0 }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 1 }, offset: null }, timings: "0.3s" }], options: { optional: true } }], options: null }], options: {} }] } });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cookie", [], null, null, null, i2.View_CookieComponent_0, i2.RenderType_CookieComponent)), i1.ɵdid(1, 4243456, null, 0, i3.CookieComponent, ["WINDOW", i4.TranslateService], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-header", [], null, null, null, i5.View_HeaderComponent_0, i5.RenderType_HeaderComponent)), i1.ɵdid(3, 245760, null, 0, i6.HeaderComponent, [i4.TranslateService, i7.Router, "WINDOW", i8.LocaleService, i9.WebsiteService, i10.DOCUMENT], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "site-content"]], [[24, "@fadeAnimation", 0]], null, null, null, null)), (_l()(), i1.ɵeld(5, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(6, 212992, [["o", 4]], 0, i7.RouterOutlet, [i7.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-footer", [], null, null, null, i11.View_FooterComponent_0, i11.RenderType_FooterComponent)), i1.ɵdid(8, 49152, null, 0, i12.FooterComponent, [], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 4, "button", [["class", "scroll-to-top"], ["id", "scroll_to_top"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.scrollToTop() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 3, ":svg:svg", [[":xml:space", "preserve"], [":xmlns:xlink", "http://www.w3.org/1999/xlink"], ["class", ""], ["height", "512px"], ["id", "Capa_1"], ["style", "enable-background:new 0 0 372.136 372.136;"], ["version", "1.1"], ["viewBox", "0 0 372.136 372.136"], ["width", "512px"], ["x", "0px"], ["xmlns", "http://www.w3.org/2000/svg"], ["y", "0px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, ":svg:g", [], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, ":svg:g", [], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, ":svg:path", [["class", "active-path"], ["d", "M371.682,143.271c-14.688-44.676-26.316-90.576-50.797-131.58c-2.447-4.284-10.403-5.508-12.239,0   c-17.748,42.228-36.108,83.844-47.736,127.908c-1.836,7.344,7.344,12.852,12.852,7.344c10.404-10.404,21.421-20.196,33.049-28.764   c-1.225,90.576,1.836,195.84-105.876,223.992c-47.736,12.24-100.98,5.509-140.76-25.092C18.557,284.644,9.377,231.4,12.437,181.828   c0-4.896-7.344-6.12-8.568-1.224c-23.868,110.772,66.096,197.064,176.256,181.764c54.468-7.344,100.368-33.048,123.624-85.068   c20.809-46.512,19.584-102.204,18.36-153c11.628,10.404,24.479,19.584,37.943,26.928   C366.173,154.9,374.129,150.004,371.682,143.271z M316.602,102.88c-3.672-1.224-6.12,0.612-7.345,3.672c0,0,0,0-0.611,0.612   c-9.792,3.06-18.36,7.956-26.316,13.464c9.18-29.988,21.42-59.364,33.048-88.128c15.912,29.988,25.092,62.424,35.496,94.248   C339.857,117.568,329.453,108.388,316.602,102.88z"], ["data-old_color", "#ffffff"], ["data-original", "#000000"], ["fill", "#ffffff"]], null, null, null, null, null))], function (_ck, _v) { _ck(_v, 3, 0); _ck(_v, 6, 0); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 6).isActivated ? i1.ɵnov(_v, 6).activatedRoute : ""); _ck(_v, 4, 0, currVal_0); }); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 180224, null, 0, i13.AppComponent, [i9.WebsiteService, i4.TranslateService, i14.FbqService, i15.GtagService, i7.Router, "WINDOW"], null, null)], null, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i13.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
