<header
  (window:scroll)="onScroll()"
  (window:resize)="onResize()"
>
  <nav
    class="main-nav"
    fxLayout="row"
    fxLayoutAlign="space-between center"
  >
    <button
      type="button"
      class="main-menu-burger"
      [class.open]="menuIsOpen"
      (click)="toggleMenu()"
      fxHide.gt-sm
    >
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </button>
    <a
      class=logo-wrapper
      routerLink=""
    >
      <img
        src="./assets/svg/logo.svg"
        alt="1development"
      >
      <h1>1development</h1>
    </a>
    <div
      class="main-menu-wrapper"
      fxLayout="row"
      fxLayoutAlign="center stretch"
    >
      <div
        class="contact-info-desktop"
        fxHide
        fxShow.gt-sm
      >
        <a
          class="separator"
          [routerLink]="basePath + '/inquiry'"
        >
          <img
            src="./assets/png/mail.png"
            alt=""
          >
          <span>{{ 'Contactează-ne' | translate }}</span>
        </a>
      </div>
      <div
        class="social-media"
        fxHide
        fxShow.gt-sm
      >
        <a
          *ngIf="websiteService.website.properties.facebook"
          [attr.href]="websiteService.website.properties.facebook"
          target="_blank"
        >
          <img
            src="./assets/png/facebook.png"
            alt="facebook"
          >
        </a>
        <a
          *ngIf="websiteService.website.properties.instagram"
          [attr.href]="websiteService.website.properties.instagram"
          target="_blank"
        >
          <img
            src="./assets/png/instagram.png"
            alt="instagram"
          >
        </a>
      </div>
      <div class="language-switcher">
        <a
          *ngIf="translate.currentLang == 'en'"
          [href]="localeService.getLocaleUrl('ro')"
        >
          <img
            src="./assets/svg/i18n/ro.svg"
            alt="RO"
          >
        </a>
        <a
          *ngIf="translate.currentLang == 'ro'"
          [href]="localeService.getLocaleUrl('en')"
        >
          <img
            src="./assets/svg/i18n/en.svg"
            alt="EN"
          >
        </a>
      </div>
    </div>
  </nav>
  <nav
    class="site-nav"
    [ngClass]="{'open': (menuIsOpen || desktopShowNavMenu)}"
  >
    <div>
      <ul>
        <li *ngFor="let item of navItems">
          <a
            [routerLink]="basePath + item.link"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="toggleMenu()"
          >
            <span *ngIf="item.name == 'Concept'" class="special-char">1</span>
            <span translate [innerHTML]="item.name"></span>
          </a>
        </li>
      </ul>
      <div
        class="contact-info-mobile"
        fxHide.gt-sm
      >
        <a [attr.href]="'tel:' + websiteService.website.phone">
          <i class="material-icons">phone</i>
          <span>{{ 'Sună' | translate }}</span>
          <span
            fxHide
            fxShow.gt-xs
          >
            {{ websiteService.website.phone | beautify }}
          </span>
        </a>
        <a [routerLink]="basePath + '/inquiry'">
          <i class="material-icons">mail</i>
          <span>{{ 'Contactează-ne' | translate }}</span>
        </a>
      </div>
    </div>
  </nav>
</header>
