<app-image-carousel [sliders]="sliders">
</app-image-carousel>

<section class="project-header">
  <div class="container">
    <div
      class="top-project-bar"
      fxLayout="column"
      fxLayout.gt-sm="row"
      fxLayoutAlign="center center"
      fxLayoutAlign.gt-sm="space-between center"
    >
      <h1>
        <img
          class="project-logo"
          [src]="blockHelper.filterBlocksByKey(blocks, 'logo').icon.url"
          [alt]="blockHelper.filterBlocksByKey(blocks, 'project').properties.title"
        >
      </h1>
    </div>
  </div>
</section>

<section class="project-details">
  <div class="container">
    <div
      fxLayout="row wrap"
      fxLayout.gt-xs="row"
      fxLayoutGap.gt-xs="35px"
    >
      <div
        fxFlex="100%"
        fxFlex.gt-xs="50%"
        class="project-story"
      >
        <div class="section-title award-header">
          <h2 class="special-letter-wrapper">
            {{ blockHelper.filterBlocksByKey(blocks, 'project-header').properties.section_title }}
          </h2>
          <label>{{ blockHelper.filterBlocksByKey(blocks, 'project-header').properties.section_label }}</label>
        </div>
        <p>{{ blockHelper.filterBlocksByKey(blocks, 'project-header').properties.description }}</p>
        <p class="quote">{{ blockHelper.filterBlocksByKey(blocks, 'project-header').properties.text }}</p>
        <div
          class="video"
          [innerHtml]="videoInnerHTML"
        >
        </div>
      </div>
      <div
        fxFlex="100%"
        fxFlex.gt-xs="50%"
        class="project-description"
      >
        <div class="location-wrapper">
          <img
            [src]="blockHelper.filterBlocksByKey(blocks, 'project-map').image.url"
            alt=""
          >
        </div>
        <p>{{ blockHelper.filterBlocksByKey(blocks, 'project-map').properties.description }}</p>
        <hr>
      </div>
    </div>
    <p class="quote">{{ blockHelper.filterBlocksByKey(blocks, 'quote1').properties.text }}</p>
  </div>
</section>

<section class="project-showcase">
  <div class="container">
    <div
      fxLayout="row wrap"
      fxLayout.gt-xs="row"
      fxLayoutGap.gt-xs="20px"
    >
      <div
        fxFlex="100%"
        fxFlex.gt-xs="33%"
      >
        <h2>{{ blockHelper.filterBlocksByKey(blocks, 'project-showcase1').properties.title }}</h2>
        <div class="img-wrapper">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d19312.468653501783!2d26.111691!3d44.451995000000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa45060c1af350932!2sCentral!5e1!3m2!1sen!2sro!4v1559049593847!5m2!1sen!2sro" width="600" height="450" frameborder="0" style="border:0" allowfullscreen></iframe>
        </div>
      </div>
      <div
        fxFlex="100%"
        fxFlex.gt-xs="33%"
      >
        <h2>{{ blockHelper.filterBlocksByKey(blocks, 'project-showcase2').properties.title }}</h2>
        <div
          class="img-wrapper"
          [ngStyle]="{'background-image': 'url(' + blockHelper.filterBlocksByKey(blocks, 'project-showcase2').image.width_560_url + ')'}"
        ></div>
      </div>
      <div
        fxFlex="100%"
        fxFlex.gt-xs="33%"
      >
        <h2>{{ blockHelper.filterBlocksByKey(blocks, 'project-showcase3').properties.title }}</h2>
        <div
          class="img-wrapper"
          [ngStyle]="{'background-image': 'url(' + blockHelper.filterBlocksByKey(blocks, 'project-showcase3').image.width_560_url + ')'}"
        ></div>
      </div>
    </div>
  </div>
</section>

<section class="project-stats">
  <p class="quote bold">{{ blockHelper.filterBlocksByKey(blocks, 'quote2').properties.text }}</p>
</section>

<section class="project-actions">
  <div
    fxLayout="column"
    fxLayout.gt-sm="row"
    fxLayoutAlign="center center"
  >
    <a
      [routerLink]="basePath + blockHelper.filterBlocksByKey(blocks, 'bottom_links').properties.button1_link"
      class="btn-outline--white"
    >{{ blockHelper.filterBlocksByKey(blocks, 'bottom_links').properties.button1 }}</a>
    <a
      [routerLink]="basePath + blockHelper.filterBlocksByKey(blocks, 'bottom_links').properties.button2_link"
      class="btn-outline--white"
    >{{ blockHelper.filterBlocksByKey(blocks, 'bottom_links').properties.button2 }}</a>
  </div>
</section>
