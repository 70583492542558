import * as tslib_1 from "tslib";
import { Injector, OnInit, OnDestroy } from '@angular/core';
import { SeoAwareBaseComponent } from '../common-theme/seo/seo-aware-base.component';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { BlockHelperService } from './../common-theme/index';
var ProjectsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ProjectsComponent, _super);
    function ProjectsComponent(blockHelper, route, injector) {
        var _this = _super.call(this, injector) || this;
        _this.blockHelper = blockHelper;
        _this.route = route;
        _this.injector = injector;
        _this.subscription = new Subscription();
        return _this;
    }
    ProjectsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscription.add(this.route.data.subscribe(function (data) {
            _this.blocks = data.blocks;
            _this.setTitle(_this.blockHelper.filterBlocksByKey(_this.blocks, 'seo').properties.title);
            _this.setMetaDescription(_this.blockHelper.filterBlocksByKey(_this.blocks, 'seo').properties.description);
        }));
    };
    ProjectsComponent.prototype.ngOnDestroy = function () {
        this.subscription.unsubscribe();
    };
    return ProjectsComponent;
}(SeoAwareBaseComponent));
export { ProjectsComponent };
