<section class="inquiry-page">
  <div class="inquiry-header">
    <h1 class="special-letter-wrapper">{{ 'Contact' | translate }}</h1>
  </div>

  <div class="container">
    <div class="contact">
      <a
        target="_blank"
        [href]="'mailto:' + websiteService.website.email"
      >
        {{ websiteService.website.email }}
      </a>
    </div>

    <app-contact-form-base>
    </app-contact-form-base>

    <br>
    <br>

    <p class="quote no-icon">{{ blockHelper.filterBlocksByKey(blocks, 'quote').properties.text }}</p>
  </div>
</section>
