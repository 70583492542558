import { Routes } from '@angular/router';
import { BlocksService } from './common-theme/api/index';
import { HomeComponent } from './home/home.component';
import { ConceptComponent } from './concept/concept.component';
import { ProjectsComponent } from './projects/projects.component';
import { Project1Component } from './projects/project1/project1.component';
import { Project2Component } from './projects/project2/project2.component';
import { Project3Component } from './projects/project3/project3.component';
import { InquiryComponent } from './inquiry/inquiry.component';
var ɵ0 = {
    blocks_categories: ['homepage']
}, ɵ1 = {
    blocks_categories: ['homepage']
}, ɵ2 = {
    blocks_categories: ['concept']
}, ɵ3 = {
    blocks_categories: ['projects']
}, ɵ4 = {
    blocks_categories: ['project1']
}, ɵ5 = {
    blocks_categories: ['project2']
}, ɵ6 = {
    blocks_categories: ['project3']
}, ɵ7 = {
    blocks_categories: ['inquiry']
};
var routes = [
    {
        path: '',
        component: HomeComponent,
        data: ɵ0,
        resolve: {
            blocks: BlocksService
        }
    },
    {
        path: ':locale',
        component: HomeComponent,
        data: ɵ1,
        resolve: {
            blocks: BlocksService
        }
    },
    {
        path: ':locale/concept',
        component: ConceptComponent,
        data: ɵ2,
        resolve: {
            blocks: BlocksService
        }
    },
    {
        path: ':locale/projects',
        component: ProjectsComponent,
        data: ɵ3,
        resolve: {
            blocks: BlocksService
        }
    },
    {
        path: ':locale/projects/baneasa1',
        component: Project1Component,
        data: ɵ4,
        resolve: {
            blocks: BlocksService
        }
    },
    {
        path: ':locale/projects/floreasca1',
        component: Project2Component,
        data: ɵ5,
        resolve: {
            blocks: BlocksService
        }
    },
    {
        path: ':locale/projects/central',
        component: Project3Component,
        data: ɵ6,
        resolve: {
            blocks: BlocksService
        }
    },
    {
        path: ':locale/inquiry',
        component: InquiryComponent,
        data: ɵ7,
        resolve: {
            blocks: BlocksService
        }
    },
    {
        path: '**',
        pathMatch: 'full',
        redirectTo: ''
    }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7 };
