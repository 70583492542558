import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { BlocksService } from './common-theme/api/index';
import { HomeComponent } from './home/home.component';
import { ConceptComponent } from './concept/concept.component';
import { ProjectsComponent } from './projects/projects.component';
import { Project1Component } from './projects/project1/project1.component';
import { Project2Component } from './projects/project2/project2.component';
import { Project3Component } from './projects/project3/project3.component';
import { InquiryComponent } from './inquiry/inquiry.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: {
      blocks_categories: ['homepage']
    },
    resolve: {
      blocks: BlocksService
    }
  },
  {
    path: ':locale',
    component: HomeComponent,
    data: {
      blocks_categories: ['homepage']
    },
    resolve: {
      blocks: BlocksService
    }
  },
  {
    path: ':locale/concept',
    component: ConceptComponent,
    data: {
      blocks_categories: ['concept']
    },
    resolve: {
      blocks: BlocksService
    }
  },
  {
    path: ':locale/projects',
    component: ProjectsComponent,
    data: {
      blocks_categories: ['projects']
    },
    resolve: {
      blocks: BlocksService
    }
  },
  {
    path: ':locale/projects/baneasa1',
    component: Project1Component,
    data: {
      blocks_categories: ['project1']
    },
    resolve: {
      blocks: BlocksService
    }
  },
  {
    path: ':locale/projects/floreasca1',
    component: Project2Component,
    data: {
      blocks_categories: ['project2']
    },
    resolve: {
      blocks: BlocksService
    }
  },
  {
    path: ':locale/projects/central',
    component: Project3Component,
    data: {
      blocks_categories: ['project3']
    },
    resolve: {
      blocks: BlocksService
    }
  },
  {
    path: ':locale/inquiry',
    component: InquiryComponent,
    data: {
      blocks_categories: ['inquiry']
    },
    resolve: {
      blocks: BlocksService
    }
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
