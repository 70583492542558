<footer
  fxLayout="column"
  fxLayoutAlign="center center"
  fxLayout.gt-sm="row"
  fxLayoutAlign.gt-sm="space-between center"
>
  <div class="footer-copyright">
    <span>&copy; {{ currentYear }} 1development all rights reserved</span>
  </div>
  <a
    href="https://aesthetic-works.com/"
    class="footer-branding"
    target="_blank"
    fxLayout="row"
    fxLayoutAlign="center center"
  >
    <span>Website made by </span>
    <img
      src="./assets/png/aw_logo.png"
      alt="Aesthetic Works"
    >
  </a>
</footer>
